import { Box } from 'components/Box';

export const MaxWidthBox = ({ children }) => (
  <Box
    css={{
      maxW: '100%'
    }}
  >
    {children}
  </Box>
);
