import * as React from "react";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import {
  Card,
  Container,
  Spacer,
  Text,
  Button,
  Link,
} from "@nextui-org/react";

import photos from "data/photos";
import slides from "data/slides";

const AI = () => {
  const [index, setIndex] = React.useState(-1);

  return (
    <>
      <main>
        <header className="store-hero">
          <Spacer y={2} />
          <Container md>
            <Text size={24} css={{ marginBottom: '2rem' }}>
              Mid Journey stuff: VTMB in the style of an 80's horror movie illustration poster.
            </Text>
            <Text size={16} css={{ marginBottom: '2rem' }}>
              <Link href='https://docs.google.com/spreadsheets/d/1QfhB0vfsZyCN1Leiu7hEkPpabCLmbcz04GwGJzo4pPo/edit?usp=sharing' target='_blank'>Prompts and links to source images</Link>
            </Text>
            <Text size={16} css={{ marginBottom: '2rem' }}>
              <Link href='https://youtu.be/FMSIXj01L68' target='_blank'>YouTube video of some of these with some choice VTMB OST in the background.</Link>
            </Text>
            <PhotoAlbum
              layout="rows"
              photos={photos}
              targetRowHeight={150}
              onClick={({ index }) => setIndex(index)}
            />
            <Lightbox
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              slides={slides}
            />
          </Container>
        </header>
      </main >
    </>
  )
}

export default AI;
