const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

const unsplashLink = (id, width, height) =>
  `https://stoogery.s3.amazonaws.com/ai-art/vtmb-80s-poster/${id}`;

const unsplashPhotos = [
  {
    "id": "andrei_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "andrei_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "arthur_kilpatrick_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "arthur_kilpatrick_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "beckett_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "beckett_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "beckett_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "beckett_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "beckett_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "beckett_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "beckett_007.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "beckett_008.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "beckett_009.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "beckett_010.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "beckett_011.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "bertram_tung_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "bertram_tung_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "bertram_tung_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "bertram_tung_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "bertram_tung_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "bertram_tung_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "bishop_vick_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "bishop_vick_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "bishop_vick_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "brother_kanker_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "brother_kanker_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "brujah_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "brujah_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "brujah_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "brujah_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "brujah_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "brujah_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "brujah_007.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "brujah_008.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "brujah_009.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "cal_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "cal_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "chunk_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "damsel_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "damsel_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "damsel_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "damsel_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gangrel_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gangrel_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gangrel_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gangrel_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gangrel_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gangrel_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gangrel_007.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gangrel_008.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gargoyle_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gargoyle_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gargoyle_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gary_golden_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "gary_golden_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "imalia_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "imalia_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "imalia_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "imalia_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "imalia_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "imalia_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_therese_voerman_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_therese_voerman_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_therese_voerman_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_therese_voerman_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_007.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_008.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_009.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_010.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_011.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_012.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_013.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_014.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "jeanette_voerman_015.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_007.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_008.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_009.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_010.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_011.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_012.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_013.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_014.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_015.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "malkavian_016.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "maximillian_strauss_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "maximillian_strauss_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "maximillian_strauss_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "maximillian_strauss_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "maximillian_strauss_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "maximillian_strauss_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "maximillian_strauss_007.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "maximillian_strauss_008.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "maximillian_strauss_009.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "mercurio_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "mercurio_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "mercurio_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "mercurio_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "mercurio_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "ming_xiao_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "ming_xiao_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "ming_xiao_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nines_rodriguez_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nines_rodriguez_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_007.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_008.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_009.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_010.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_011.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_012.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_013.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_014.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_015.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_016.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_017.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_018.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "nosferatu_019.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "pisha_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "pisha_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "pisha_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "pisha_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "sebastian_lacroix_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "sebastian_lacroix_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "sebastian_lacroix_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "sebastian_lacroix_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "sebastian_lacroix_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "sebastian_lacroix_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "sebastian_lacroix_007.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "sheriff_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "skelter_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "smiling_jack_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "smiling_jack_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "smiling_jack_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "smiling_jack_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "smiling_jack_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "smiling_jack_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "therese_voerman_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "therese_voerman_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "therese_voerman_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "therese_voerman_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "tremere_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "tremere_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "tremere_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "vandal_cleaver_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "velvet_velour_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "velvet_velour_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "velvet_velour_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "velvet_velour_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "vtmb_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "vtmb_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "vtmb_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "vtmb_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "vtmb_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "vtmb_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_001.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_002.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_003.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_004.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_005.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_006.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_007.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_008.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_009.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_010.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_011.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_012.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_013.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_014.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_015.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_016.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_017.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_018.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_019.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_020.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_021.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_022.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_023.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_024.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_025.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_026.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_027.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_028.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_029.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_030.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_031.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_032.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_033.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_034.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_035.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_036.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_037.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_038.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_039.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_040.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_041.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_042.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_043.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_044.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_045.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_046.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_047.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_048.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_049.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_050.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_051.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_052.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_053.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_054.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_055.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_056.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_057.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_058.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_059.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_060.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_061.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_062.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_063.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_064.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_065.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_066.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_067.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_068.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_069.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_070.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_071.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_072.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_073.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_074.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_075.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_076.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_077.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_078.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_079.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_080.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_081.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_082.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_083.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_084.png",
    "width": 1536,
    "height": 1024
  },
  {
    "id": "wod_085.png",
    "width": 1536,
    "height": 1024
  }
];

const photos = unsplashPhotos.map((photo, index) => {
  const width = photo.width * 4;
  const height = photo.height * 4;
  return {
    src: unsplashLink(photo.id, width, height),
    key: `${index}`,
    width,
    height,
    images: breakpoints.map((breakpoint) => {
      const breakpointHeight = Math.round((height / width) * breakpoint);
      return {
        // src: unsplashLink(photo.id, breakpoint, breakpointHeight),
        src: unsplashLink(photo.id, photo.width, photo.height),
        // width: breakpoint,
        // height: breakpointHeight
        width: photo.width,
        height: photo.height
      };
    })
  };
});

export default photos;
