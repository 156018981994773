import React from 'react'
// import { BrowserRouter } from 'react-router-dom';
import { createTheme, NextUIProvider } from '@nextui-org/react';
// import useDarkMode from 'use-dark-mode';
// import './App.scss'
import "yet-another-react-lightbox/styles.css";
import { HashRouter as Router } from 'react-router-dom';
import AppRoutes from './AppRoutes';

const primaryColors = {
  primaryLight: '$pink200',
  primaryLightHover: '$pink300',
  primaryLightActive: '$pink400',
  primaryLightContrast: '$pink600',
  primary: '#f327c6',
  primaryBorder: '$pink500',
  primaryBorderHover: '$pink600',
  primarySolidHover: '$pink700',
  primarySolidContrast: '$white',
  primaryShadow: '$blue500',
}

const lightTheme = createTheme({
  type: 'light',
  theme: {
    colors: { ...primaryColors }
  }
})

const darkTheme = createTheme({
  type: 'dark',
  theme: {
    colors: { ...primaryColors }
  }
})

function App() {
  // const darkMode = useDarkMode(true)
  const darkMode = true

  return (
    <Router>
      {/* <NextUIProvider theme={darkMode.value ? darkTheme : lightTheme}> */}
      <NextUIProvider theme={darkTheme}>
        <AppRoutes />
      </NextUIProvider>
    </Router>
  )
}

export default App
