import { Loading } from "@nextui-org/react";

export const LoadingSpinner = () => {
  return <Loading
  css={{
    position: "absolute",
    bottom: "3rem",
    right: "3rem",
  }} />;
}
