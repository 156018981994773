import {
  Card,
  Container,
  Spacer,
  Text,
  Tooltip
} from "@nextui-org/react";

const Home = () => {
  return (
    <>
      <main>
        <header className="store-hero">
          <Spacer y={2} />
          <Container md>
            <Card variant="shadow" css={{ backgroundColor: '#00000000' }}>
              <Card.Body css={{ padding: "$24" }}>
                <Text h1 css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%" }}>
                  The Stoogery
                </Text>
                <Text size={24}>
                  A centralized locale for all of Odious Stooge's hobbies and bad ideas <Text size={24} css={{ cursor: 'pointer', textGradient: "45deg, $blue600 -20%, $green600 50%" }}>
                    <Tooltip placement="bottom" color="error" content={"It ain't pretty, but its good enough for now"}>quick and dirty edition</Tooltip>
                  </Text>
                </Text>
              </Card.Body>
            </Card>
          </Container>
        </header>
      </main>
    </>
  )
}

export default Home;
