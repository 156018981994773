import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Navbar, Text, Button, Link, Image } from '@nextui-org/react'
import { AppLogo } from 'components/AppLogo'
import { MaxWidthBox } from 'components/MaxWidthBox'
import leftDark from 'assets/gradient-left-dark.svg'
import rightDark from 'assets/gradient-right-dark.svg'
import { MoonIcon } from 'components/MoonIcon'
import { SunIcon } from 'components/SunIcon'
import { LoadingSpinner } from 'components/LoadingSpinner'
// import useDarkMode from 'use-dark-mode'
import useLocalStorage from 'hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from 'constants';
import { routes } from './../routes'
import { Outlet } from 'react-router-dom';
import {
  Link as DomLink
} from 'react-router-dom';


const navLinks = [
  {
    order: 0,
    name: 'Home',
    href: routes.home,
  },
  {
    order: 1,
    name: 'AI Art',
    href: routes.ai,
  },
  {
    order: 2,
    name: 'Booty Bot',
    href: routes.bootybot,
  },
]

const MainLayout = ({ children }) => {
  const [variant, setVariant] = React.useState('sticky')
  const location = useLocation()
  // const darkMode = useDarkMode(true)
  const darkMode = true
  const [showLoading, setShowLoading] = useLocalStorage(LOCAL_STORAGE_KEYS.SHOW_LOADING, false);

  return (
    <>
      {/* Gives full width and height */}
      <div id='app-container'>

        {/* Wraps navbar and children content to allow navbar overlay in scroll */}
        <MaxWidthBox>

          {/* Sticky Navbar */}
          <Navbar variant={variant} maxWidth='fluid'>

            {/* Branding */}
            <Navbar.Brand>
              {/* <AppLogo /> */}
              <Text b color='inherit' hideIn='xs'>
                Stoogery
              </Text>
            </Navbar.Brand>

            {/* Cycle through nav links */}
            <Navbar.Content hideIn='xs' variant='highlight'>
              {navLinks.map((link) => (
                <Navbar.Link
                  key={link.order}
                  isActive={location.pathname === link.href}
                >
                  <DomLink to={link.href}>
                    {link.name}
                  </DomLink>
                </Navbar.Link>
              ))}
            </Navbar.Content>

            {/* Nav actions */}
            <Navbar.Content>

            </Navbar.Content>

          </Navbar>

          {/* Rest of the content */}
          <main>
            {/* {children} */}
            <Outlet />
          </main>

          {/* Supah cool SVGs that I ripped from NextUI (https://nextui.org/) - Many Thanks NextUI 🙏 */}
          <Image alt="gradient blue background" src={leftDark} css={{
            position: 'fixed',
            bottom: '-30%',
            left: '-40%',
            display: 'block',
            zIndex: '-1',
          }} />
          <Image alt="gradient violet background" src={rightDark} css={{
            position: 'fixed',
            top: '-10%',
            right: '-50%',
            display: 'block',
            zIndex: '-1',
          }} />

        </MaxWidthBox>

        {
          showLoading &&
          <LoadingSpinner />
        }

      </div>
    </>
  )
}

export default MainLayout;
