import { Navigate, Route, Routes } from 'react-router';
import { routes } from './routes';

import AI from 'pages/AI';
import BootyBot from 'pages/BootyBot';
import Home from 'pages/Home';
import MainLayout from 'pages/MainLayout';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index path={routes.home} element={<Home />} />
        <Route path={routes.ai} element={<AI />} />
        <Route path={routes.bootybot} element={<BootyBot />} />
        <Route path='*' element={<Navigate to={routes.home} />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
