import {
  Card,
  Container,
  Spacer,
  Text,
  Button,
} from "@nextui-org/react";
// import confetti from 'canvas-confetti';
import useLocalStorage from 'hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from 'constants';


const BootyBot = () => {
  const [hasSeenBefore, setHasSeenBefore] = useLocalStorage(LOCAL_STORAGE_KEYS.SEEN_BB_BUTTON_BEFORE, false);
  const [showLoading, setShowLoading] = useLocalStorage(LOCAL_STORAGE_KEYS.SHOW_LOADING, false);

  const handleClick = () => {
    // use confetti and then 2 seconds later redirect to bootybot.me
    // setShowLoading(true);
    handleConfetti();
    window.open('https://bootybot.me', '_blank').focus();

  };

  const handleConfetti = () => {
    // use confetti and then 2 seconds later redirect to bootybot.me
  };

  return (
    <>
      <main>
        <header className="store-hero">
          <Spacer y={2} />
          <Container md>
            <Card variant="shadow" css={{ backgroundColor: '#00000000' }}>
              <Card.Body css={{ padding: "$24" }}>
                <Container>
                  <Text size={24}>
                    A Discord bot that sends the channel random pics of buns 🍑
                  </Text>
                  <Button
                    shadow
                    color="gradient"
                    size="xl"
                    auto
                    as="a"
                    css={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                    onPress={handleClick}>
                    Go to bootybot.me (⚠️ NSFW)
                  </Button>
                </Container>
              </Card.Body>
            </Card>
          </Container>
        </header>
      </main >
    </>
  )
}

export default BootyBot;
